import React from "react";
import PropTypes from "prop-types";

export const HTMLContent = ({ children }) => (
  <div className="HTMLContent" dangerouslySetInnerHTML={{ __html: children }} />
);

const Content = ({ children }) => <div>{children}</div>;

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
