import React, { useMemo } from "react";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Heading, Container } from "../../components/core";
import Page from "../../components/Page";
import { Vague } from "../../components/svg";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import { HTMLContent } from "../../components/Content";

const PolitiqueDeConfidentialite = ({ data }) => {
  const markdownRemark = data.markdownRemark;

  const searchRegExp = /<a href=/gi;
  const replaceWith = '<a target="_blank" href=';
  const eventHtml = useMemo(
    () => markdownRemark.html.replace(searchRegExp, replaceWith),
    [markdownRemark.html]
  );

  return (
    <Page>
      <SEO
        title="Politique de confidentialité"
        description="Association marseillaise créée en 2017, Clean my Calanques milite pour la préservation de l'environnement et a pour ambition de sensibiliser l'ensemble de la population aux enjeux environnementaux."
      />
      <Container sx={{ paddingTop: [8, null, 10], paddingBottom: 10 }}>
        <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
          Politique de confidentialité
        </Heading>
        <Vague
          color="primary"
          sx={{
            marginTop: 6,
            marginBottom: 3,
          }}
        />
        <HTMLContent>{eventHtml}</HTMLContent>
      </Container>
    </Page>
  );
};

export default PolitiqueDeConfidentialite;

export const query = graphql`
  query PolitiqueDeConfidentialiteQuery {
    markdownRemark(fields: { slug: { eq: "/politique-de-confidentialite/" } }) {
      id
      html
    }
  }
`;
